import React, { useEffect, useState } from 'react'

function Discover() {
  const [height, setHeight] = useState('45vh');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setHeight('180vh');
      } else {
        setHeight('40vh');
      }
    };

    handleResize(); // Set the initial height based on the current screen size
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <section className="more-interested bgweb4">
        <div className="inner block-container-1" style={{height:height}}>
          <div
            className="t2 more-interested-trigger"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseMoreInterested"
          >
            Discover other exciting topics
          </div>
          <div
            id="collapseExample"
            className="more-interested-content "
            aria-labelledby="collapseExample"
          >
            <ul className="more-interested-content-inner">
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-football-kit.html" title="Football">
                  Football
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-handball-jerseys.html" title="Handball">
                  Handball
                </a>
              </li>
              <li>
                <a
                  style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-table-tennis-jersey.html"
                  title="Table tennis"
                >
                  Table tennis
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-darts-jerseys.html" title="Darts">
                  Darts
                </a>
              </li>
              <li>
                <a href="en/customised-running-shirt.html" title="Running" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Running
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-volleyball-jerseys.html" title="Volleyball">
                  Volleyball
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-bowling-jerseys.html" title="Bowling">
                  Bowling
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-cycling-clothing.html" title="Cycling">
                  Cycling
                </a>
              </li>
              <li>
                <a
                  href="en/create-custom-basketball-jersey.html"
                  title="Basketball"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Basketball
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-esports-jersey.html" title="eSports">
                  eSports
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-badminton-jerseys.html" title="Badminton">
                  Badminton
                </a>
              </li>
              <li>
                <a
                  style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-fieldhockey-jersey.html"
                  title="Field hockey"
                >
                  Field hockey
                </a>
              </li>
              <li>
                <a href="en/kids-jersey-set.html" title="Kids jersey set" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Kids jersey set
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-rugby-jerseys.html" title="Rugby">
                  Rugby
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-floorball-jerseys.html" title="Floorball">
                  Floorball
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-mtb-jersey.html" title="MTB">
                  MTB
                </a>
              </li>
              <li>
                <a
                  href="de/wintersport-bekleidung-selbst-gestalten.html"
                  title="Wintersport "
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Wintersport
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-fitnessshirts.html" title="Fitness">
                  Fitness
                </a>
              </li>
              <li>
                <a
                  href="en/print-company-running-shirt.html"
                  title="Company runs"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Company runs
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-fun-shirts.html" title="fun shirt">
                  fun shirt
                </a>
              </li>
              <li>
                <a href="en/club-clothing.html" title="Club clothing" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Club clothing
                </a>
              </li>
              <li>
                <a href="en/create-jersey-set.html" title="Shirt configurator" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Shirt configurator
                </a>
              </li>
              <li>
                <a
                  href="en/design-your-own-kids-jersey.html"
                  title="Jerseys for children"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Jerseys for children
                </a>
              </li>
              <li>
                <a
                  href="en/design-your-own-jersey.html"
                  title="Jersey Configurator"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Jersey Configurator
                </a>
              </li>
              <li>
                <a href="en/jersey-printing.html" title="Jersey printing" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Jersey printing
                </a>
              </li>
              <li>
                <a href="en/gifts-for-athletes.html" title="Gifts for athletes" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Gifts for athletes
                </a>
              </li>
              <li>
                <a
                  href="en/gifts-for-club-members.html"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                  title="Gifts for club members"
                >
                  Gifts for club members
                </a>
              </li>
              <li>
                <a href="en/team-trip-t-shirt.html" title="Team trip shirts" style={{fontFamily:"sans-serif", textDecoration:"none"}}>
                  Team trip shirts
                </a>
              </li>
              <li>
                <a
                  href="en/design-your-own-promotion-shirt.html"
                  title="Promotion shirt"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Promotion shirt
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-socks.html" title="Socks">
                  Socks
                </a>
              </li>
              <li>
                <a style={{fontFamily:"sans-serif", textDecoration:"none"}} href="en/custom-scarf.html" title="Scarf">
                  Scarf
                </a>
              </li>
              <li>
                <a
                  href="en/personalised-boxer-shorts.html"
                  title="Personalised Boxer Shorts"
                  style={{fontFamily:"sans-serif", textDecoration:"none"}}
                >
                  Personalised Boxer Shorts
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
  )
}

export default Discover
import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function SimilarProducts({ product }) {
  const sliderRef = useRef(null); 
  const products = [
    {
      id: '781037',
      name: "Paulo men’s football shorts",
      imageUrl: "https://www.spized.com/thumbnail/6f/ba/7a/1701099577/fussball-hose-herren-basic13-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/9c/7c/02/1701099575/fussball-hose-herren-basic-action_600x600.jpg",
      price: '€24.90',
      unitPrice: '€37.90',
    },
    {
      id: '780152',
      name: "Sports socks Unisex",
      imageUrl: "https://www.spized.com/thumbnail/94/b8/6e/1709547241/socken3-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/f7/2d/73/1698934396/Socken-04_600x600.jpg",
      price: '€13.90',
      unitPrice: '€27.90',
    },
    {
      id: '781036',
      name: "Paulo men’s football shorts",
      imageUrl: "https://www.spized.com/thumbnail/6f/ba/7a/1701099577/fussball-hose-herren-basic13-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/9c/7c/02/1701099575/fussball-hose-herren-basic-action_600x600.jpg",
      price: '€24.90',
      unitPrice: '€37.90',
    },
    {
      id: '780153',
      name: "Sports socks Unisex",
      imageUrl: "https://www.spized.com/thumbnail/94/b8/6e/1709547241/socken3-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/f7/2d/73/1698934396/Socken-04_600x600.jpg",
      price: '€13.90',
      unitPrice: '€27.90',
    },
    {
      id: '781035',
      name: "Paulo men’s football shorts",
      imageUrl: "https://www.spized.com/thumbnail/6f/ba/7a/1701099577/fussball-hose-herren-basic13-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/9c/7c/02/1701099575/fussball-hose-herren-basic-action_600x600.jpg",
      price: '€24.90',
      unitPrice: '€37.90',
    },
    {
      id: '780150',
      name: "Sports socks Unisex",
      imageUrl: "https://www.spized.com/thumbnail/94/b8/6e/1709547241/socken3-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/f7/2d/73/1698934396/Socken-04_600x600.jpg",
      price: '€13.90',
      unitPrice: '€27.90',
    },
    {
      id: '781038',
      name: "Paulo men’s football shorts",
      imageUrl: "https://www.spized.com/thumbnail/6f/ba/7a/1701099577/fussball-hose-herren-basic13-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/9c/7c/02/1701099575/fussball-hose-herren-basic-action_600x600.jpg",
      price: '€24.90',
      unitPrice: '€37.90',
    },
    {
      id: '780155',
      name: "Sports socks Unisex",
      imageUrl: "https://www.spized.com/thumbnail/94/b8/6e/1709547241/socken3-1_600x600.jpg",
      hoverImageUrl: "https://www.spized.com/thumbnail/f7/2d/73/1698934396/Socken-04_600x600.jpg",
      price: '€13.90',
      unitPrice: '€27.90',
    },
    // Add more products as needed
  ];

  const settings = {
    dots: false,
    infinite: products.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      console.log('Prev clicked');
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      console.log('Next clicked'); 
    }
  };

  return (
    <div style={{ position: 'relative', padding: '20px', maxWidth: '100%', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center' }}>Similar Products</h2>
      <Slider {...settings} ref={sliderRef}>
        {products.map((product) => (
          <div key={product.id} style={{ padding: '10px' }}>
            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', boxSizing: 'border-box' }}>
              <div style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '100%' }}>
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </div>
              <h3 style={{ fontSize: '1.1rem', margin: '10px 0' }}>{product.name}</h3>
              <p style={{ margin: '0', color: '#555' }}>Price for 10 pcs.: {product.price}</p>
              <p style={{ margin: '0', color: '#555' }}>Unit price: {product.unitPrice}</p>
            </div>
          </div>
        ))}
      </Slider>
      <div style={buttonContainerStyle}>
        <div onClick={handlePrev} style={iconStyle}>
          <FaChevronLeft />
        </div>
        <div onClick={handleNext} style={iconStyle}>
          <FaChevronRight />
        </div>
      </div>
    </div>
  );
}

const iconStyle = {
  fontSize: '24px',
  color: '#007bff',
  cursor: 'pointer',
  padding: '10px',
  background: 'white',
  borderRadius: '50%',
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
};

const buttonContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

export default SimilarProducts;

import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
    tokenDetails: Cookies.get('tokenDetails') ? JSON.parse(Cookies.get('tokenDetails')) : null,
    signupData: Cookies.get('signupData') ? JSON.parse(Cookies.get('signupData')) : [],
}

export const user_information = createSlice({
    name: "user",
    initialState,
    reducers: {
        setProfileData(state, action) {
            state.signupData = action.payload;
            Cookies.set('signupData', JSON.stringify(state.signupData));
        },
        logout(state) {
            state.tokenDetails = null;
            Cookies.remove('tokenDetails');
            Cookies.remove("signupData");
        },
        setLoginData(state, action) {
            state.tokenDetails = action.payload;
            Cookies.set('tokenDetails', JSON.stringify(state.tokenDetails));
        },
        clearDataIfInvalid(state) {
            if (!state.signupData || !state.tokenDetails || !state.token) {
                state.signupData = null;
                state.tokenDetails = null;
                state.token = null;
                Cookies.remove('signupData');
                Cookies.remove('tokenDetails');
            }
        }
    }
});

export const { setProfileData,setLoginData,logout,clearDataIfInvalid} = user_information.actions;
export default user_information.reducer;

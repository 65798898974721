import React from 'react'
import { AiOutlineDown } from 'react-icons/ai'
import { FaAngleRight } from 'react-icons/fa'

function Banner() {
  return (
    <div>
           <div className="cms-section background-gradient-1 brands-emotion pos-0 cms-section-default">
                <div className="cms-section-default full-width">
                  <div className="cms-block spized-banner-full-size white-text pos-0 cms-block-spized-banner">
                    <div className="cms-block-container">
                      <div className="cms-block-container-row row cms-row ">
                        <div
                          className="col-12"
                          data-cms-element-id="018ba9504eec7933acd658e8875145b4"
                        >
                          <div className="spized--banner-info-box">
                            <div className="image-box">
                              <img
                                // src="asset/img/no-imageb090.svg?1729065116"
                                src="https://www.spized.com/media/3f/62/36/1701076424/Header_01-INT.jpg"
                                srcset
                                data-srcset="https://www.spized.com/media/3f/62/36/1701076424/Header_01-INT.jpg 1921w, https://www.spized.com/thumbnail/3f/62/36/1701076424/Header_01-INT_1920x1920.jpg 1920w, https://www.spized.com/thumbnail/3f/62/36/1701076424/Header_01-INT_800x800.jpg 800w, https://www.spized.com/thumbnail/3f/62/36/1701076424/Header_01-INT_1280x1280.jpg 1280w"
                                className="img-fluid image-slider-image f_lazy desctop-image-view desctop-mobile-type"
                                width="50"
                                height="50"
                              />
                              <img
                                // src="asset/img/no-imageb090.svg?1729065116"
                                src="https://www.spized.com/media/d0/69/9e/1701076424/Header_01-mobil_INT.jpg"
                                srcset
                                data-srcset="https://www.spized.com/media/d0/69/9e/1701076424/Header_01-mobil_INT.jpg 1921w, https://www.spized.com/thumbnail/d0/69/9e/1701076424/Header_01-mobil_INT_1920x1920.jpg 1920w, https://www.spized.com/thumbnail/d0/69/9e/1701076424/Header_01-mobil_INT_800x800.jpg 800w, https://www.spized.com/thumbnail/d0/69/9e/1701076424/Header_01-mobil_INT_1280x1280.jpg 1280w"
                                className="img-fluid image-slider-image f_lazy mobile-image-view"
                                width="50"
                                height="50"
                              />
                            </div>
                            <div className="inner-box">
                              <div className="spized--banner-slider-inner">
                                <div className="spized--banner-slider-description">
                                  <h1 className="t1">
                                    Customised sportswear for you and your team
                                  </h1>
                                  <p className="t2">
                                    With our 3D configurator, you can easily
                                    design professional-quality sportswear made
                                    just for you. Why not kit out your club too
                                    with our attractive discounts.
                                  </p>
                                  <p>
                                    <a
                                      className="btn btn-primary button2 customize-button"
                                      href="en/custom/sports-clothing.html"
                                      target="_blank"
                                      style={{ backgroundColor: "#000000" }}
                                    >
                                      Design sportswear now
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      className="btn button2 button-secondary"
                                      title
                                      href="en/configurator6730.html?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&amp;apiArticle=781001&amp;productNumber=781001"
                                      style={{ color: "#ffffff" }}
                                      target="_blank"
                                    >
                                      Open 3D configurator
                                      <FaAngleRight className="icon-arrow-right-bold"/>
                                    </a>
                                  </p>
                                  <div className="down-arrow-icon btn-primary btn button-icon-big">
                                    <AiOutlineDown className="icon-arrow-down-black"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </div>
  )
}

export default Banner
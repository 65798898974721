import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import ProductSlider from "./ProductSlider";

function Discounts() {
  const products = [
    {
      id: '782002',
      title: "Swish women’s basketball jersey",
      imgSrc: "https://www.spized.com/thumbnail/c8/ee/34/1639577454/basketablltrikot-damen-selbst-gestalten-tank-top-loewen-rot-rheinalnd-lions_(1)_800x800.jpg",
      configUrl: "en/configurator5c10.html?apiLikeCfgId=e0c644047c75dcf12413f34032db31cb8fc3896b49f6f76bcc164d58f82edd73f1612d0b635c25b0e8a154c919ae5d1e1b9cfe8cdc51864f0afc2b4ce0df64e6&apiArticle=782002&productNumber=782002.SPIZED0500",
      detailUrl: "en/basketball-products/swish-women-s-basketball-jersey/782002.html?v=SPIZED0500"
    },
    {
      id: '782003',
      title: "Men’s basketball shooter shirt",
      imgSrc: "https://www.spized.com/thumbnail/94/3c/67/1639577454/basketballtrikot-shooter-shirt-selbst-gestalten-bg-biba-sparkasse-rot-blau_(1)_1280x1280.jpg",
      configUrl: "en/configurator2ed1.html?apiLikeCfgId=358555cbd45a189f283ce021bc712f34d8d78c500c9b9f8aff4fd21383a0a24133c13558dd79ef7a2a4c8236033222f523b635d4b619eb3489fc35c84deb7b03&apiArticle=782003&productNumber=782003.SPIZED0499",
      detailUrl: "en/basketball-products/men-s-basketball-shooter-shirt/782003.html?v=SPIZED0499"
    },
    {
      id: '784021',
      title: "Viborg men’s handball jersey l/s",
      imgSrc: "https://www.spized.com/thumbnail/28/6d/67/1639408485/hanballtrikot-langarm-selber-gestalten-osg-fredersdorf-sonnenschutz-langarm-orange-schwarz_1280x1280.jpg",
      configUrl: "en/configurator515a.html?apiLikeCfgId=123fc4b0b55d1e43da5b95491985f2bebd948dda3d2ba2eeb2667e9b35422a8853c28b7ee7503833a33f1b39b05068f83088ac2ad82fec116924550ab00c8403&apiArticle=784021&productNumber=784021.SPIZED014",
      detailUrl: "en/handball-products/viborg-men-s-handball-jersey-l-s/784021.html?v=SPIZED014"
    },
    {
      id: '784009',
      title: "Skjern women’s handball jersey",
      imgSrc: "https://www.spized.com/thumbnail/44/b7/19/1639408478/handballtrikot-selber-gestalten-handball-kurzarm-hsg-refrath-rot_800x800.jpg",
      configUrl: "en/configurator893a.html?apiLikeCfgId=3c83f338429dcd2f677ce95236925cfee5aba132751b1b6b6aa7c8db7cb8450e2efb066c103f4cdc7bec71b0b79ee9ee44bd6418b41c1451aa95976c33b57fd8&apiArticle=784009_INTERPOWER&productNumber=784009_INTERPOWER.SPIZED_TRACK",
      detailUrl: "en/handball-products/skjern-women-s-handball-jersey/784009-interpower.html?v=SPIZED-TRACK"
    },
    // Add more products as needed
  ];
  return (
    <div>
      <div className="cms-section  pos-5 cms-section-default">
        <div className="cms-section-default full-width">
          <div className="cms-block block-container-3 pos-0 cms-block-spized-usp-blocks">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row ">
                <div className="col-12">
                  <div className="image-text-slider-element cms-element-spized-usp-blocks">
                    <Swiper
                      pagination={{ clickable: true }}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          allowTouchMove: true,
                          loop: true,
                        },
                        575: {
                          slidesPerView: 1,
                          allowTouchMove: true,
                          loop: true,
                        },
                        767: {
                          slidesPerView: 1,
                          allowTouchMove: true,
                          loop: true,
                        },
                        992: {
                          slidesPerView: 3,
                          allowTouchMove: false,
                        },
                        1220: {
                          slidesPerView: 3,
                          allowTouchMove: false,
                        },
                      }}
                      loop={false} // Set to true if you want continuous looping
                    >
                      {/* Slide 1 */}
                      <SwiperSlide>
                        <div className="image-slider-item-container emotion-spized-usp-blocks-wrapper">
                          <div className="image-container">
                            <img
                              src="https://www.spized.com/media/4f/03/b5/1639564035/Attraktive-Rabatte.svg"
                              className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                              width="50"
                              height="50"
                              alt="Attractive discounts"
                            />
                          </div>
                          <div className="info-block">
                            <div className="title t1small">
                              Attractive discounts
                            </div>
                            <div className="description">
                              In addition to our standard quantity discounts, we
                              offer special club deals for larger orders.
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      {/* Slide 2 */}
                      <SwiperSlide>
                        <div className="image-slider-item-container emotion-spized-usp-blocks-wrapper">
                          <div className="image-container">
                            <img
                              src="https://www.spized.com/media/74/ab/6b/1686314115/qualitat.svg"
                              className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                              width="50"
                              height="50"
                              alt="Satisfied customers"
                            />
                          </div>
                          <div className="info-block">
                            <div className="title t1small">
                              Satisfied customers
                            </div>
                            <div className="description">
                              Over 16,000 happy customers have already ordered
                              from Spized - you can be the next one!
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      {/* Slide 3 */}
                      <SwiperSlide>
                        <div className="image-slider-item-container emotion-spized-usp-blocks-wrapper">
                          <div className="image-container">
                            <img
                              src="https://www.spized.com/media/b0/52/20/1639408455/profidesign-check.svg"
                              className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                              width="50"
                              height="50"
                              alt="Personal design review"
                            />
                          </div>
                          <div className="info-block">
                            <div className="title t1small">
                              Personal design review
                            </div>
                            <div className="description">
                              Our graphic designers check every design you
                              create with our jersey configurator and will
                              contact you if necessary.
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div className="swiper-pagination"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cms-block block-container-2 pos-1 cms-block-image-cover">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row ">
                <div
                  className="col-12"
                  data-cms-element-id="0191992ef05d7605879d0b8fc6d36856"
                >
                  <div className="cms-element-image justify-content-start">
                    <a
                      href="en/newsletter-subscribe.html"
                      className="cms-image-link"
                    >
                      <div
                        className="cms-image-container is-cover"
                        style={{ minHeight: "340px" }}
                      >
                        <img
                          width="1920"
                          height="622"
                          //   src="asset/img/no-imageb090.svg?1729065116"
                          src="https://www.spized.com/media/2e/3f/36/1724851034/5_(1).png"
                          className="cms-image f_lazy desctop-image-view desctop-mobile-type"
                          loading="lazy"
                          data-object-fit="cover"
                        />
                        <img
                          width="600"
                          height="600"
                          //   src="asset/img/no-imageb090.svg?1729065116"
                          src="https://www.spized.com/media/29/g0/20/1724852798/3_(1).png"
                          className="cms-image f_lazy mobile-image-view"
                          loading="lazy"
                          data-object-fit="cover"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductSlider/>
        </div>
      </div>
    </div>
  );
}

export default Discounts;

import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function VerticalSlider({ product }) {
    const settings = {
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        speed: 500,
        infinite: false,
        arrows: true,
        prevArrow: (
            <button 
                style={{
                    backgroundColor: "transparent", 
                    border: "none", 
                    fontSize: "24px", 
                    position: "absolute", 
                    top: "10px",
                    left: "50%", 
                    zIndex: 10, 
                    transform: "translateX(-50%)",
                    cursor: "pointer",
                }}
            >
                ▲
            </button>
        ),
        nextArrow: (
            <button 
                style={{
                    backgroundColor: "transparent", 
                    border: "none", 
                    fontSize: "24px", 
                    position: "absolute", 
                    bottom: "10px", 
                    left: "50%", 
                    zIndex: 10, 
                    transform: "translateX(-50%)",
                    cursor: "pointer",
                }}
            >
                ▼
            </button>
        ),
    };

    const images = [
        {
            src: "https://www.spized.com/media/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1.jpg",
            fullImage: "https://www.spized.com/media/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1.jpg",
            alt: "Rio men’s football jersey",
        },
        {
            src: "https://www.spized.com/media/e2/50/cf/1701099577/fussball-trikot-herren-kurzarm-basic3-1.jpg",
            fullImage: "https://www.spized.com/media/e2/50/cf/1701099577/fussball-trikot-herren-kurzarm-basic3-1.jpg",
            alt: "Rio men’s football jersey",
        },
        {
            src: "https://www.spized.com/media/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1.jpg",
            fullImage: "https://www.spized.com/media/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1.jpg",
            alt: "Rio men’s football jersey",
        },
        {
            src: "https://www.spized.com/media/e2/50/cf/1701099577/fussball-trikot-herren-kurzarm-basic3-1.jpg",
            fullImage: "https://www.spized.com/media/e2/50/cf/1701099577/fussball-trikot-herren-kurzarm-basic3-1.jpg",
            alt: "Rio men’s football jersey",
        },
        {
            src: "https://www.spized.com/media/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1.jpg",
            fullImage: "https://www.spized.com/media/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1.jpg",
            alt: "Rio men’s football jersey",
        },
        {
            src: "https://www.spized.com/media/e2/50/cf/1701099577/fussball-trikot-herren-kurzarm-basic3-1.jpg",
            fullImage: "https://www.spized.com/media/e2/50/cf/1701099577/fussball-trikot-herren-kurzarm-basic3-1.jpg",
            alt: "Rio men’s football jersey",
        },
    ];

    return (
        <div style={{ width: "20%", height: "500px", position: "relative" }}> {/* Increased height */}
            <div className="col-lg-6 product-detail-media">
                <div style={{ width: "200px", margin: "0 auto", height: "100%", position: "relative" }}> {/* Ensure full height */}
                    <Slider {...settings}>
                        {images.map((image, index) => (
                            <div key={index} style={{ padding: "10px" }}>
                                <img
                                    src={image.src}
                                    alt={image.alt}
                                    style={{ width: "100%", cursor: "zoom-in" }}
                                    onClick={() => window.open(image.fullImage, "_blank")}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default VerticalSlider;

import React from 'react';
import Slider from 'react-slick'; // Importing 'react-slick' for image slider
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const OrderHistory = () => {
    // Sample order data
    const orders = [
        {
            orderId: 'ORD12345',
            expectedDeliveryDate: '2024-11-15',
            images: [
                'https://via.placeholder.com/150',
                'https://via.placeholder.com/150',
            ]
        },
        {
            orderId: 'ORD12346',
            expectedDeliveryDate: '2024-11-20',
            images: [
                'https://via.placeholder.com/150',
                'https://via.placeholder.com/150',
            ]
        },
        // Add more orders as needed
    ];

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Order History</h2>
            <div style={styles.orderList}>
                {orders.map((order, index) => (
                    <div key={index} style={styles.orderCard}>
                        <Slider {...sliderSettings} style={styles.slider}>
                            {order.images.map((img, idx) => (
                                <div key={idx} style={styles.imageContainer}>
                                    <img src={img} alt="Order" style={styles.image} />
                                </div>
                            ))}
                        </Slider>
                        <div style={styles.infoContainer}>
                            <p style={styles.orderId}><strong>Order ID:</strong> {order.orderId}</p>
                            <p style={styles.deliveryDate}><strong>Expected Delivery:</strong> {order.expectedDeliveryDate}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Inline styles
const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Open Sans',
        maxWidth: '800px',
        margin: '0 auto',
    },
    header: {
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '20px',
    },
    orderList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    orderCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
    },
    slider: {
        width: '100%',
        maxWidth: '300px',
        marginBottom: '15px',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        width: '100%',
        borderRadius: '10px',
        objectFit: 'cover',
    },
    infoContainer: {
        textAlign: 'center',
        fontSize: '16px',
        color: '#555',
    },
    orderId: {
        marginBottom: '5px',
    },
    deliveryDate: {
        color: '#888',
    }
};

export default OrderHistory;

import React from 'react';

function Configurator() {
  const items = [
    {
      imgSrc: "https://www.spized.com/media/f5/cc/e1/1695287230/pfeile.svg",
      title: "Wide range of products",
      description: "You can design a lot more than just jerseys. We also have shorts, hoodies, tracksuits and more.",
    },
    {
      imgSrc: "https://www.spized.com/media/19/26/7b/1699354877/nachbestellungen-jederzeit-moeglich.svg",
      title: "No minimum order quantity",
      description: "Production starts from just 1 item. For repeat orders, we guarantee the same price as your last order.",
    },
    {
      imgSrc: "https://www.spized.com/media/28/d2/6a/1639408457/attraktive-rabatte.svg",
      title: "No additional costs",
      description: "All printing costs are included, no matter how many different elements you include on your jersey.",
    },

  ];

  return (
    <div>
      <div className="cms-section bg-color pos-2 cms-section-default" style={{ backgroundColor: "#f4f4f4" }}>
        <div className="cms-section-default full-width">
          <div className="cms-block block-container-2 pos-0 cms-block-spized-advanced-usps">
            <div className="cms-block-container" style={{ padding: "40px 0 0 0" }}>
              <div className="cms-block-container-row row cms-row">
                <div className="col-12">
                  <div className="spized-advanced-usps-element">
                    <div className="spized-advanced-usps-inner block-container">
                      <h2 className="t1">Your advantages with our 3D configurator</h2>
                      <div className="image-text-slider-element cms-element-spized-advanced-usps" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {items.map((item, index) => (
                          <div key={index} className="usp-list-item" style={{ flex: '1 1 calc(33.33% - 20px)', margin: '10px', }}>
                            <div className="usp-list-wrapper">
                              <div className="image-container">
                                <img
                                  src={item.imgSrc}
                                  className="img-fluid image-slider-image f_lazy desctop-image-view desctop-type"
                                  width="50"
                                  height="50"
                                  alt={item.title}
                                />
                              </div>
                              <div className="info-block">
                                <div className="title t2">{item.title}</div>
                                <div className="description">{item.description}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="button-container">
                        <a className="btn button2 usps-button" href="en/benefits.html" style={{ backgroundColor: "#000000", color: "#fff", padding: "10px 20px", textDecoration: "none", borderRadius: "5px" }}>
                          Your benefits at a glance
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configurator;

import React from "react";

function Shipping() {
  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };

  const thStyle = {
    backgroundColor: "#f2f2f2",
    padding: "10px",
    border: "1px solid #ddd",
    textAlign: "center",
  };

  const tdStyle = {
    padding: "10px",
    border: "1px solid #ddd",
    textAlign: "center",
  };

  return (
    <div>
      <div
        className="tab-pane f_detail-tab-panel"
        id="shipping-tab-panel"
        role="tabpanel"
        aria-labelledby="shipping-tab"
      >
        <div className="offcanvas-body">
          <div className="shipping-tab">
            <h2 style={{ margin: "20px 0", textAlign: "center" }}>Shipping Costs</h2>
            <p style={{ textAlign: "center", marginBottom: "20px" }}>
              We currently ship our products via UPS within the EU, Great Britain, and Switzerland. Sample orders are available to Germany, Austria, Italy, France, Belgium, and the Netherlands.
            </p>
            <div className="sizes-content">
              <h3 style={{ fontSize: "large", textAlign: "center" }}>Shipping Costs Germany</h3>
              <div className="text" style={{ textAlign: "center" }}>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Number of Articles</th>
                      <th style={thStyle}>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>Up to 9 articles</td>
                      <td style={tdStyle}>4,90 EUR*</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Up to 49 articles</td>
                      <td style={tdStyle}>8,00 EUR*</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Up to 98 articles</td>
                      <td style={tdStyle}>16,00 EUR*</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>From 99 articles</td>
                      <td style={tdStyle}>20,00 EUR*</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Prices incl. VAT</p>
              </div>

              <h3 style={{ fontSize: "large", textAlign: "center" }}>Shipping Costs EU, CH, UK</h3>
              <div className="text" style={{ textAlign: "center" }}>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Number of Articles</th>
                      <th style={thStyle}>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>Up to 9 articles</td>
                      <td style={tdStyle}>9,90 EUR*</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Up to 49 articles</td>
                      <td style={tdStyle}>20,00 EUR*</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>Up to 98 articles</td>
                      <td style={tdStyle}>40,00 EUR*</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>From 99 articles</td>
                      <td style={tdStyle}>60,00 EUR*</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Prices incl. VAT</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shipping;

import React, { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { Link } from "react-router-dom";

function Sports() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(true);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const list = [
    {
      cat_id: 1,
      title: "Badminton",
      subTitles: [
        { id: 1, sub: "Badminton Jersey" },
        { id: 2, sub: "Badminton Shorts" },
        { id: 3, sub: "Badminton Tank Top" },
      ],
    },
    {
      cat_id: 2,
      title: "Football",
      subTitles: [
        { id: 1, sub: "Football Jersey" },
        { id: 2, sub: "Football Shorts" },
        { id: 3, sub: "Football Shoes" },
      ],
    },
    {
      cat_id: 3,
      title: "Basketball",
      subTitles: [
        { id: 1, sub: "Basketball Jersey" },
        { id: 2, sub: "Basketball Shorts" },
        { id: 3, sub: "Basketball Shoes" },
      ],
    },
    {
      cat_id: 4,
      title: "Tennis",
      subTitles: [
        { id: 1, sub: "Tennis Racket" },
        { id: 2, sub: "Tennis Shorts" },
        { id: 3, sub: "Tennis Shoes" },
      ],
    },
    {
      cat_id: 5,
      title: "Running",
      subTitles: [
        { id: 1, sub: "Running Jersey" },
        { id: 2, sub: "Running Shorts" },
        { id: 3, sub: "Running Shoes" },
      ],
    },
  ];

  // Split the list into chunks of 3 items each
  const chunkedList = [];
  for (let i = 0; i < list.length; i += 3) {
    chunkedList.push(list.slice(i, i + 3));
  }

  return (
    <div onMouseEnter={toggleDropdown} onMouseLeave={closeDropdown}>
      <div
        className="nav-link main-navigation-link"
        title="Sports"
        style={{
          padding: "0px",
          padding: "0px",
          width: "max-content",
          marginRight: "43px",
        }}
      >
        <div className="main-navigation-link-text" style={{gap:"10px",
              display:"flex",
              alignItems:"center",
              justifyContent:"center"}}>
          <span
            style={{
              fontFamily: "sans-serif",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Design your own!
          </span>
          <AiOutlineDown className="icon-arrow-down-black" color="black" />
        </div>
      </div>

      {isDropdownOpen && (
        <div
          className="dropdown-container"
          style={{
            position: "absolute",
            top: "33px",
            left: 0,
            width: "100vw",
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            padding: "18px 0px 0px 28px",
          }}
        >
          <div style={{ display: "flex", flexGrow: 1 }}>
            {chunkedList.map((chunk, columnIndex) => (
              <div
                key={columnIndex}
                className="navigation-flyout-col"
                style={{
                  flexBasis: "33%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                {chunk.map((item) => (
                  <div key={item.cat_id} style={{ paddingBottom: "10px" }}>
                    <div title={item.title}>
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {item.title}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "5px",
                      }}
                    >
                      {item.subTitles.map((subItem) => (
                        <Link
                          key={subItem.id}
                          onClick={() => window.location.href = "/listing"}
                          style={{
                            fontFamily: "sans-serif",
                            color: "black",
                            textDecoration: "none",
                            marginBottom: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {subItem.sub}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div
            className="navigation-flyout-col highlights"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "10px",
              height: "100%",
            }}
          >
            <img
              src="https://www.spized.com/thumbnail/26/73/a7/1702032557/navigations-popup_socken-neu_1920x1920.jpg"
              className="product-image"
              width="300"
              height="300"
              alt="Product"
              style={{ marginBottom: "10px", flexShrink: 0 }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Sports;

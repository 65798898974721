import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Filter from "./Filter";
import Design from "./Design";
import MoreInteresting from "./MoreInteresting";

function ListingPage() {
  const productData = [
    {
      id: 1,
      link: "../../../football-products/multisport-men-s-polo-shirt/780050.html",
      title: "Multisport men’s polo shirt",
      images: [
        "https://www.spized.com/thumbnail/fc/a8/1e/1701085886/polo-herren-basic1-2_600x600.jpg",
        "https://www.spized.com/thumbnail/2d/1d/3f/1701085889/polo-herren-basic6-1_600x600.jpg",
      ],
      badgeText: "Slim Fit",
      badgeColor: "#6a6d71",
      price: "€49.90",
    },
    {
      id: 2,
      link: "../../../men-s-sport-polo/780130.html",
      title: "Men's sport polo",
      images: [
        "https://www.spized.com/thumbnail/f4/34/21/1701097908/polo-herren-premium1-3_600x600.jpg",
        "https://www.spized.com/thumbnail/8f/6a/0f/1701097910/polo-herren-premium6-1_600x600.jpg",
      ],
      badgeText: "New",
      badgeColor: "#6b1c44",
      price: "€63.90",
    },
    {
      id: 3,
      link: "../../../football-products/rio-men-s-football-jersey/781001.html",
      title: "Rio men’s football jersey",
      images: [
        "https://www.spized.com/thumbnail/a1/b1/2a/1701099577/fussball-trikot-herren-kurzarm-basic1-1_600x600.jpg",
        "https://www.spized.com/thumbnail/8c/f6/71/1701099577/fussball-trikot-herren-kurzarm-basic4-1_600x600.jpg",
      ],
      badgeText: "",
      badgeColor: "",
      brandLogo:
        "https://www.spized.com/media/29/9b/3a/1639408455/element-2.svg",
      unitPriceFor10: "€36.90",
      unitPriceFor1: "€56.90",
    },
    {
      id: 4,
      link: "../../../football-products/brasilia-men-s-football-jersey/781065.html",
      title: "Brasilia men's football jersey",
      images: [
        "https://www.spized.com/thumbnail/7a/df/74/1701085029/fussball-trikot-herren-kurzarm-premium1-2_600x600.jpg",
        "https://www.spized.com/thumbnail/00/4a/53/1701085029/fussball-trikot-herren-kurzarm-premium6-1_600x600.jpg",
      ],
      badgeText: "Bestseller",
      badgeColor: "#ff7900",
      additionalBadgeText: "Premium",
      additionalBadgeColor: "#000000",
      brandLogo:
        "https://www.spized.com/media/29/9b/3a/1639408455/element-2.svg",
      unitPriceFor10: "€46.90",
      unitPriceFor1: "€66.90",
    },
    {
      id: 5,
      link: "../../../football-products/brasilia-men-s-football-jersey/781065.html",
      title: "Brasilia men's football jersey",
      images: [
        "https://www.spized.com/thumbnail/7a/df/74/1701085029/fussball-trikot-herren-kurzarm-premium1-2_600x600.jpg",
        "https://www.spized.com/thumbnail/00/4a/53/1701085029/fussball-trikot-herren-kurzarm-premium6-1_600x600.jpg",
      ],
      badgeText: "Bestseller",
      badgeColor: "#ff7900",
      additionalBadgeText: "Premium",
      additionalBadgeColor: "#000000",
      brandLogo:
        "https://www.spized.com/media/29/9b/3a/1639408455/element-2.svg",
      unitPriceFor10: "€46.90",
      unitPriceFor1: "€66.90",
    },
  ];

  return (
    <div>
      <body className="body-container default is-ctl-spized-listing-with-seo-box is-ctl-product_list is-ctl- is-act-">
        <Header />

        <main className="content-main">
          <div data-flashbags-container="true" className="flashbags"></div>
          <div className="container-main">
            <div className="cms-page">
              <div className="cms-sections">
                <div className="cms-section block-container-2 listing-content-emotion pos-0 cms-section-sidebar">
                  <div className="cms-section-sidebar cms-section-sidebar-mobile-wrap boxed row">
                    <Filter />
                    <div className="cms-section-sidebar-main-content col-lg-8 col-xl-9">
                      <div className="cms-block  pos-2 cms-block-product-listing">
                        <div className="cms-block-container">
                          <div className="cms-block-container-row row cms-row">
                            <div className="col-12">
                              <div className="row cms-listing-row js-listing-wrapper">
                                {productData.map((product) => (
                                  <div
                                    key={product.id}
                                    className="cms-listing-col col-sm-6 col-lg-6 col-xl-4"
                                    style={{ marginTop: 20, marginBottom: 40 }}
                                  >
                                    <div className="card product-box box-standard">
                                      <div className="card-body">
                                        <div className="product-image-wrapper">
                                          <a
                                            href={product.link}
                                            title={product.title}
                                            className="product-image-link is-standard has-hover-image"
                                          >
                                            <img
                                              width="600"
                                              height="600"
                                              src={product.images[0]}
                                              alt={product.title}
                                              className="f_coverImage cover-image product-image is-standard"
                                            />
                                            <img
                                              width="600"
                                              height="600"
                                              src={product.images[1]}
                                              alt={product.title}
                                              className="f_hoverImage is-transparent hover-image product-image is-standard"
                                            />
                                          </a>
                                          <div className="product-badges">
                                            {product.badgeText && (
                                              <span
                                                className="badge"
                                                style={{
                                                  backgroundColor:
                                                    product.badgeColor,
                                                }}
                                              >
                                                {product.badgeText}
                                              </span>
                                            )}
                                            {product.additionalBadgeText && (
                                              <span
                                                className="badge"
                                                style={{
                                                  backgroundColor:
                                                    product.additionalBadgeColor,
                                                }}
                                              >
                                                {product.additionalBadgeText}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="product-info">
                                          <a
                                            href={product.link}
                                            className="product-name"
                                            title={product.title}
                                          >
                                            {product.title}
                                          </a>
                                          <div className="product-price-info">
                                            <ul className="multiple-price-group">
                                              <li className="product-price-wrapper">
                                                Unit price for{" "}
                                                <span className="product-count">
                                                  10
                                                </span>{" "}
                                                pcs.:{" "}
                                                <span className="product-price">
                                                  {product.unitPriceFor10}
                                                </span>
                                              </li>
                                              <div className="product--unit-price">
                                                <div className="price--content content--default">
                                                  Unit price for{" "}
                                                  <span className="f_spizedUnitArticleCount">
                                                    1
                                                  </span>{" "}
                                                  pcs.:{" "}
                                                  <span className="f_spizedArticleUnitPrice article-unit-price">
                                                    {product.unitPriceFor1}
                                                  </span>
                                                </div>
                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Design />
        <MoreInteresting />
        <Footer />
      </body>
    </div>
  );
}

export default ListingPage;

import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  items: Cookies.get("items") ? JSON.parse(Cookies.get("items")) : [],
  totalItems: Cookies.get("totalItem") ? Cookies.get("totalItem") : 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart: (state, action) => {
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id
      );
      if (!existingItem) {
        state.items.push(action.payload);
        state.totalItems += 1;
      }
      Cookies.set('items', JSON.stringify(state.items));
      Cookies.set('totalItem', state.totalItems);
    },
    removeCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      state.totalItems -= 1;
      Cookies.set('items', JSON.stringify(state.items));
      Cookies.set('totalItem', state.totalItems);
    },
    updateCartQuantity: (state, action) => {
        console.log("Payload : ",action.payload)
      const { id, quantity } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (quantity > 0) {
        if (existingItem) {
          existingItem.quantity = quantity; // Update quantity if item exists
        } else {
          state.items.push({ ...action.payload, quantity }); // Add new item if not in cart
        }
      } else if (existingItem) {
        // Remove item from the cart if quantity is zero
        state.items = state.items.filter((item) => item.id !== id);
      }
      Cookies.set('items', JSON.stringify(state.items));
      Cookies.set('totalItem', state.totalItems);
    }
  },
});

export const {
  addCart,
  removeCart,
  updateCartQuantity,
  clearCart
} = cartSlice.actions;
export default cartSlice.reducer;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn); 
  const { cartItems } = location.state || {};

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");

  const totalPrice = cartItems.reduce(
    (total, item) =>
      total + parseFloat(item.unitPriceFor1.replace("€", "")) * item.quantity,
    0
  );

  const handleProceedToPay = async () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    const formData = {
      address: {
        name,
        phoneNumber,
        street,
        addressLine,
        city,
        state,
        country,
        pincode,
      },
      cartItems,
      totalPrice,
    };

    try {
      const response = await axios.post("", formData, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        alert("Payment processed successfully!");
      }
    } catch (error) {
      console.error("Payment processing failed:", error);
      alert("Payment processing failed. Please try again.");
    }
  };

  return (
    <div>
      <div
        style={{
          padding: "20px",
          maxWidth: "1280px",
          margin: "auto",
          fontFamily: "Open Sans, sans-serif",
          color: "#333",
        }}
      >
        <h2
          style={{
            color: "#333",
            textAlign: "center",
            borderBottom: "3px solid #4CAF50",
            paddingBottom: "10px",
          }}
        >
          Checkout
        </h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              flex: "1",
              minWidth: "280px",
              backgroundColor: "#f9f9f9",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <h3
              style={{
                color: "#333",
                marginBottom: "10px",
                fontFamily: "sans-serif",
              }}
            >
              Cart Items
            </h3>
            {cartItems.map((item) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  borderRadius: "8px",
                  marginBottom: "12px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                }}
              >
                <img
                  src={item.images[0]}
                  alt={item.title}
                  style={{
                    width: "80px",
                    height: "80px",
                    marginRight: "15px",
                    borderRadius: "8px",
                  }}
                />
                <div style={{ flex: 1 }}>
                  <h4
                    style={{
                      margin: "0 0 5px",
                      color: "#333",
                      fontWeight: "600",
                    }}
                  >
                    {item.title}
                  </h4>
                  <p style={{ margin: "0", color: "#555" }}>
                    Price: €
                    {parseFloat(item.unitPriceFor1.replace("€", "")) *
                      item.quantity}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#333",
                      }}
                    >
                      Quantity : {item.quantity}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "1.7em",
                color: "#333",
              }}
            >
              Total: €{totalPrice.toFixed(2)}
            </div>
          </div>
          <div
            style={{
              flex: "1",
              minWidth: "280px",
              backgroundColor: "#f9f9f9",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <h3
              style={{
                color: "#333",
                marginBottom: "10px",
                fontFamily: "sans-serif",
              }}
            >
              Delivery Address
            </h3>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Address Line"
              value={addressLine}
              onChange={(e) => setAddressLine(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              style={inputStyle}
            />

            <button
              onClick={handleProceedToPay}
              style={{
                marginTop: "20px",
                padding: "14px",
                width: "100%",
                backgroundColor: "#4CAF50",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
            >
              Proceed to Pay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const inputStyle = {
  width: "100%",
  padding: "12px",
  margin: "8px 0",
  borderRadius: "8px",
  border: "1px solid #ddd",
  boxShadow: "inset 0 1px 3px rgba(0,0,0,0.1)",
};

export default Checkout;

import React from "react";

function SizeTable() {
  return (
    <div style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
      <div
        className="tab-pane f_detail-tab-panel"
        id="sizes-tab-panel"
        role="tabpanel"
        aria-labelledby="sizes-tab"
        style={{ marginBottom: "20px" }}
      >
        <div className="offcanvas-body">
          <div className="product-detail-sizes tab-pane-container">
            <div className="h3 product-detail-description-title" style={{ fontSize: "24px", marginBottom: "15px", fontWeight: "bold", fontFamily:"sans-serif" }}>
              Size Table
            </div>
            <div className="product-detail-sizes-text" style={{ marginBottom: "20px", lineHeight: "1.5", color: "#555" }}>
              To give you the most accurate assessment of the required size, you will find a size recommendation here. For certainty, we also offer the option of having a{" "}
              <a href="../../sample-order.html" style={{ color: "#007bff", textDecoration: "underline" }}>free sample set sent</a> to your home.
            </div>
            <div className="sizes-content">
              <h3>
                <span style={{ fontSize: "20px", fontWeight: "500", fontFamily:"sans-serif" }}>Men's Size Tables</span>
              </h3>
              <div className="text" style={{ marginBottom: "20px" }}>
                <br />
                <table className="table--men" style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr style={{ backgroundColor: "#e9ecef" }}>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>Sizes in cm</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>XS</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>S</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>M</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>L</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>XL</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>2XL</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>3XL</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>4XL</th>
                      <th style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>5XL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>Chest</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>85 - 90</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>91 - 96</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>97 - 102</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>103 - 108</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>109 - 114</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>115 - 120</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>121 - 126</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>127 - 132</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>133 - 138</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>Waist</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>73 - 90</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>79 - 96</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>85 - 102</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>91 - 108</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>97 - 114</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>103 - 120</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>109 - 126</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>115 - 132</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>120 - 137</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>Body height</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>160 - 180</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>165 - 185</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>170 - 190</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>175 - 195</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>180 - 200</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>185 - 205</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>185 - 205</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>185 - 205</td>
                      <td style={{ padding: "10px", border: "1px solid #dee2e6", fontFamily:"sans-serif" }}>185 - 205</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="text" style={{ lineHeight: "1.5", color: "#555" }}>
                <b style={{ letterSpacing: "0px", fontSize: "16px" }}>
                  How to Measure Correctly:
                </b>
                <br />
                Chest: Measure horizontally with the tape measure around the widest part of your torso.
                <br />
                Waist: Measure horizontally around the narrowest part of your waist.
                <br />
                Hip: Measure with the tape measure where the hip circumference is largest.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SizeTable;


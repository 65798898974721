import React from 'react';

function Design() {
  return (
    <div>
      <div className="category-listing" style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className="category-listing-inner bghighlightColor"
          style={{
            backgroundColor: "#000000",
            display: "flex",
            flexDirection: "row",
            maxWidth: "100%",
            width: "100%",
            alignSelf: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="category-listing-info-box" style={{ flex: 1, padding: "20px" }}>
            <div className="category-listing-title t2" style={{ fontSize: "1.5em", color: "#FFFFFF", marginBottom: "10px" }}>
              Design your own jersey - limitless possibilities
            </div>
            <div className="category-listing-subtitle t6" style={{ fontSize: "1em", color: "#AAAAAA", marginBottom: "20px" }}>
              Use our 3D configurator to easily design your own unique piece
              and choose your dream combination from a wide range of designs,
              colours and patterns.
            </div>
            <a
              href="../../../design-your-own-jersey.html"
              className="category-listing-button btn btn-primary"
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                padding: "10px 20px",
                textDecoration: "none",
                borderRadius: "5px",
                display: "inline-block",
                fontSize: "1em",
                textAlign: "center"
              }}
            >
              All about the configurator
            </a>
          </div>
          <div className="category-listing-banner-box" style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <img
              width="100%"
              style={{ maxWidth: "547px", height: "auto" }}
              src="https://www.spized.com/media/62/dd/7f/1699346383/Produktubersicht_Banner-Animation_01-min.gif"
              className="category-image f_lazy desktop-image-view desktop-type"
              alt="Design your own jersey - limitless possibilities"
              title="Design your own jersey - limitless possibilities"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Design;

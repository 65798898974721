import React, { useState } from "react";
import Discover from "../Components/Discover";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  removeCart,
  updateCartQuantity,
} from "../../Redux/Cart_System";
import { useNavigate } from "react-router-dom";

const CartSec = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const cartItemsFromRedux = useSelector((state) => state.cart.items);
  console.log("cartItemsFromRedux : ", cartItemsFromRedux);

  const handleIncrement = (product) => {
    const newQuantity = product.quantity + 1;
    setQuantity(newQuantity);
    console.log("quantity : ", newQuantity);
    dispatch(updateCartQuantity({ id: product.id, quantity: newQuantity }));
  };

  const handleDecrement = (product) => {
    if (product.quantity > 1) {
      const newQuantity = product.quantity - 1;
      setQuantity(newQuantity);
      console.log("quantity : ", newQuantity);
      dispatch(updateCartQuantity({ id: product.id, quantity: newQuantity }));
    } else if (product.quantity === 1) {
      dispatch(removeCart(product.id));
      setQuantity(0);
    }
  };

  const removeItem = (id) => {
    dispatch(removeCart(id));
    setQuantity(0);
  };

  const handleProceedToCheckout = () => {
    navigate("/checkout", { state: { cartItems: cartItemsFromRedux } });
  };

  const totalPrice = cartItemsFromRedux.reduce(
    (total, item) =>
      total + parseFloat(item.unitPriceFor1.replace("€", "")) * item.quantity,
    0
  );

  return (
    <div>
      <Header />
      <div
        style={{
          padding: "20px",
          maxWidth: "1280px",
          margin: "auto",
          fontFamily: "Open Sans, sans-serif",
          color: "#333",
        }}
      >
        <h2
          style={{
            color: "#333",
            textAlign: "center",
            borderBottom: "3px solid #4CAF50",
            paddingBottom: "10px",
          }}
        >
          Shopping Cart
        </h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {/* Left side: Cart Items */}
          {cartItemsFromRedux.length === 0 ? (
            <div
              style={{
                flex: "1",
                minWidth: "280px",
                backgroundColor: "#f9f9f9",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                textAlign: "center",
              }}
            >
              <h3
                style={{
                  color: "#ff5c5c",
                  fontSize: "1.8em",
                  fontWeight: "600",
                }}
              >
                Your cart is empty
              </h3>
              <p
                style={{
                  color: "#555",
                  fontSize: "1.2em",
                }}
              >
                Looks like you haven't added anything to your cart yet.
              </p>
            </div>
          ) : (
            <div
              style={{
                flex: "1",
                minWidth: "280px",
                backgroundColor: "#f9f9f9",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            >
              {cartItemsFromRedux.map((item) => (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px",
                    borderRadius: "8px",
                    marginBottom: "12px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                >
                  <img
                    src={item.images[0]}
                    alt={item.title}
                    style={{
                      width: "80px",
                      height: "80px",
                      marginRight: "15px",
                      borderRadius: "8px",
                    }}
                  />
                  <div style={{ flex: 1 }}>
                    <h4
                      style={{
                        margin: "0 0 5px",
                        color: "#333",
                        fontWeight: "600",
                      }}
                    >
                      {item.title}
                    </h4>
                    <p style={{ margin: "0", color: "#555" }}>
                      Price: €
                      {parseFloat(item.unitPriceFor1.replace("€", "")) *
                        item.quantity}
                    </p>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      <button
                        onClick={() => handleDecrement(item)}
                        style={{
                          backgroundColor: "#e0e0e0",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </button>
                      <span
                        style={{
                          margin: "0 12px",
                          fontSize: "1em",
                          fontWeight: "500",
                          color: "#333",
                        }}
                      >
                        {item.quantity}
                      </span>
                      <button
                        onClick={() => handleIncrement(item)}
                        style={{
                          backgroundColor: "#e0e0e0",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          marginLeft: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={() => removeItem(item.id)}
                    style={{
                      backgroundColor: "#ff5c5c",
                      color: "#fff",
                      border: "none",
                      padding: "8px 12px",
                      cursor: "pointer",
                      borderRadius: "6px",
                      fontWeight: "bold",
                      marginLeft: "10px",
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <div
                style={{
                  marginTop: "20px",
                  fontWeight: "bold",
                  fontSize: "1.7em",
                  color: "#333",
                }}
              >
                Total: €{totalPrice.toFixed(2)}
              </div>
            </div>
          )}
          {cartItemsFromRedux.length === 0 ? (
            ""
          ) : (
            <button
              style={{
                marginTop: "20px",
                padding: "14px",
                width: "100%",
                backgroundColor: "#4CAF50",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
              disabled={cartItemsFromRedux.length === 0}
              onClick={handleProceedToCheckout}
            >
              Proceed to Checkout
            </button>
          )}
        </div>
      </div>
      <Discover />
      <Footer />
    </div>
  );
};

export default CartSec;

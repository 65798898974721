import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setProfileData } from "../../Redux/user_information";
import {
  config_Api,
  getProfile,
  updateImage,
  updateProfile,
} from "../../Services/Operations/ProductAPI";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Discover from "../Components/Discover";

const Profile = () => {
  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;
  const dispatch = useDispatch();
  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm();

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    gender: "",
    dob: "",
    profileimg: null,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [configUrl, setConfigUrl] = useState("");
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const isMounted = useRef(true);

  if (isSubmitSuccessful) {
    reset({
      name: "",
      mobile: "",
      email: "",
      password: "",
    });
  }

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        console.log(".....profile........")
        const response = await getProfile(token);
        const { name, email, gender, dob, profileimg } = response.data;
        setProfile({ name, email, gender, dob, profileimg });
        console.log("Profile :", response);

        if (response.success === true) {
          dispatch(setProfileData(response.data));
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [profileUpdated]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await config_Api();
        console.log("Config in Home:", response);
        if (response.success === true) {
          setConfigUrl(response.data.assetUrl);
        }
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onSubmit = async (data) => {
    try {
      const response = await updateProfile(data, token);
      console.log("Profile updated:", response);
      if (response.success === true) {
        dispatch(setProfileData(response.data));
        setIsEditing(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleProfileImgSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await updateImage(formData, token);
      console.log("Profile image updated:", response);

      if (response.success) {
        setProfile((prevProfile) => ({
          ...prevProfile,
          profileimg: response.data.profileimg,
        }));
        setProfileUpdated(true);
        setTimeout(() => {
          setProfileUpdated(false);
        }, 500);
        setIsEditing(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  const styles = {
    container: {
      width: "100%",
      maxWidth: "600px",
      margin: "40px auto",
      padding: "30px",
      background: "#fff",
      borderRadius: "15px",
      boxShadow: "0 12px 40px rgba(0, 0, 0, 0.1)",
      fontFamily: "'Helvetica Neue', sans-serif",
    },
    header: {
      fontSize: "32px",
      color: "#000",
      marginBottom: "25px",
      fontWeight: "600",
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
    },
    profilePicture: {
      marginBottom: "25px",
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
    },
    img: {
      width: "140px",
      height: "140px",
      borderRadius: "50%",
      border: "6px solid #fff",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
      objectFit: "cover",
    },
    formGroup: {
      marginBottom: "25px",
      textAlign: "left",
    },
    label: {
      display: "block",
      marginBottom: "10px",
      fontSize: "16px",
      color: "#333",
      fontWeight: "bold",
    },
    input: {
      width: "100%",
      padding: "14px",
      border: "1px solid #ccc",
      borderRadius: "12px",
      fontSize: "15px",
      outline: "none",
      transition: "border 0.3s ease",
    },
    inputFocus: {
      border: "1px solid #4A90E2",
    },
    button: {
      padding: "12px 30px",
      backgroundColor: "#4A90E2",
      color: "#fff",
      border: "none",
      borderRadius: "50px",
      cursor: "pointer",
      fontSize: "16px",
      marginTop: "15px",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#357ABD",
    },
    profileDetails: {
      fontSize: "18px",
      color: "#000",
    },
    profileDetailItem: {
      marginBottom: "20px",
    },
    strong: {
      color: "#000",
      fontWeight: "600",
    },
    fileInput: {
      display: "block",
      marginTop: "20px",
      marginLeft: "20px",
    },
    fileInputButton: {
      padding: "10px 25px",
      backgroundColor: "#28a745",
      border: "none",
      color: "white",
      borderRadius: "5px",
      cursor: "pointer",
      marginLeft: "20px",
      marginTop: "10px",
    },
    cancelButton: {
      backgroundColor: "#dc3545",
      marginLeft: "20px",
    },
    inputSection: {
      display: "block",
      margin: "0 auto",
      width: "80%",
    },
    switchButton: {
      fontSize: "16px",
      color: "#fff",
      cursor: "pointer",
      background: "none",
      border: "none",
      padding: 15,
      backgroundColor: "black",
      borderRadius: "10px",
    },
  };

  return (
    <div>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.header}>My Profile</h1>
        <div style={styles.profilePicture}>
          {profile.profileimg ? (
            <img
              src={`${configUrl}/${profile.profileimg}`}
              alt="Profile"
              style={styles.img}
            />
          ) : (
            <img
              src={`${configUrl}/${profile.profileimg}`}
              alt="Profile"
              style={styles.img}
            />
          )}
          {isEditing && (
            <form onSubmit={handleProfileImgSubmit}>
              <input
                type="file"
                onChange={handleFileChange}
                style={styles.fileInput}
              />
              <button type="submit" style={styles.fileInputButton}>
                Change Profile Picture
              </button>
            </form>
          )}
        </div>

        {isEditing ? (
          <form onSubmit={handleSubmit(onSubmit)} style={styles.inputSection}>
            <div style={styles.formGroup}>
              <label htmlFor="name" style={styles.label}>
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                // value={profile.name}
                // onChange={handleInputChange}
                style={styles.input}
                {...register("name", { required: "name is required" })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="email" style={styles.label}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                // value={profile.email}
                // onChange={handleInputChange}
                style={styles.input}
                {...register("email", { required: "email is required" })}
              />{" "}
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="gender" style={styles.label}>
                Gender
              </label>
              <select
                id="gender"
                name="gender"
                // value={profile.gender}
                // onChange={handleInputChange}
                style={styles.input}
                {...register("gender", { required: "gender is required" })}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errors.gender && <p>{errors.gender.message}</p>}
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="dob" style={styles.label}>
                Date of Birth
              </label>
              <input
                type="date"
                id="dob"
                name="dob"
                // value={profile.dob}
                // onChange={handleInputChange}
                style={styles.input}
                {...register("dob", { required: "dob is required" })}
              />
              {errors.dob && <p>{errors.dob.message}</p>}
            </div>
            <button type="submit" style={styles.button}>
              Save Changes
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              style={{ ...styles.button, ...styles.cancelButton }}
            >
              Cancel
            </button>
          </form>
        ) : (
          <div style={styles.profileDetails}>
            <p style={styles.profileDetailItem}>
              <strong style={styles.strong}>Name:</strong> {profile.name}
            </p>
            <p style={styles.profileDetailItem}>
              <strong style={styles.strong}>Email:</strong> {profile.email}
            </p>
            <p style={styles.profileDetailItem}>
              <strong style={styles.strong}>Gender:</strong>{" "}
              {profile.gender || "Not specified"}
            </p>
            <p style={styles.profileDetailItem}>
              <strong style={styles.strong}>Date of Birth:</strong>{" "}
              {profile.dob || "Not specified"}
            </p>
            <button
              onClick={() => setIsEditing(true)}
              style={styles.switchButton}
            >
              Edit Profile
            </button>
          </div>
        )}
      </div>
      <Discover />
      <Footer />
    </div>
  );
};

export default Profile;

import React from 'react';
const designItems = [
  {
    id: 1,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0088",
    variantUrl: "https://www.spized.com/en/detail/018e2dc58f4870e9937ee2a79ee6813f",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/73/8b/d1/1698026708/69292fdb-1eba-934a-7458-cab39bcc34b2_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 2,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0494",
    variantUrl: "https://www.spized.com/en/detail/018e3de970d470d0a81818e5616e8040",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/e2/09/3e/1710439226/7a710923-6cac-5541-f08a-287d5d14b770_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 3,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0088",
    variantUrl: "https://www.spized.com/en/detail/018e2dc58f4870e9937ee2a79ee6813f",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/73/8b/d1/1698026708/69292fdb-1eba-934a-7458-cab39bcc34b2_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 4,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0494",
    variantUrl: "https://www.spized.com/en/detail/018e3de970d470d0a81818e5616e8040",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/e2/09/3e/1710439226/7a710923-6cac-5541-f08a-287d5d14b770_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 5,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0088",
    variantUrl: "https://www.spized.com/en/detail/018e2dc58f4870e9937ee2a79ee6813f",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/73/8b/d1/1698026708/69292fdb-1eba-934a-7458-cab39bcc34b2_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 6,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0494",
    variantUrl: "https://www.spized.com/en/detail/018e3de970d470d0a81818e5616e8040",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/e2/09/3e/1710439226/7a710923-6cac-5541-f08a-287d5d14b770_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 7,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0088",
    variantUrl: "https://www.spized.com/en/detail/018e2dc58f4870e9937ee2a79ee6813f",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/73/8b/d1/1698026708/69292fdb-1eba-934a-7458-cab39bcc34b2_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
  {
    id: 8,
    configuratorUrl: "/en/configurator?apiLikeCfgId=053e62542d485bb80aebcf74f956030704a4c285f1cd2108425d3d689433d8e2ee8fc69074ed6bdab04a05dfc5a2718003cba90bc6347e97ac8738e1b2c7c49f&apiArticle=781001&productNumber=781001.SPIZED0494",
    variantUrl: "https://www.spized.com/en/detail/018e3de970d470d0a81818e5616e8040",
    imageUrl: "../../../bundles/spized/asset/img/no-imageb090.svg?1729065116",
    dataSrc: "https://www.spized.com/thumbnail/e2/09/3e/1710439226/7a710923-6cac-5541-f08a-287d5d14b770_781001_600x600.png",
    altText: "Rio men’s football jersey",
    title: "Rio men’s football jersey"
  },
];

function Designs() {
    return (
      <div >
        <div
          className="tab-pane f_detail-tab-panel"
          id="designs-tab-panel"
          role="tabpanel"
          aria-labelledby="designs-tab"
        >
          
          <div className="offcanvas-body">
            <div className="product-detail-designs tab-pane-container tab-pane-designs">
              <div className="design-inner-box">
                <div className="h3 product-detail-description-title" style={{ fontFamily: "sans-serif", color: "black", fontWeight: "bold" }}>
                  Design overview
                </div>
                <div className="product-detail-designs-text">
                  You have the choice. Decide between different standard
                  designs and then customise them as desired. With your own
                  colours, labels and logos, you can turn your team wear
                  into a really unique item. All costs are included in the
                  price. If you already have clear ideas about your jersey
                  and cannot implement them with the configurator, you can
                  also simply contact our service team. Access the{" "}
                  <a href="../../special-design.html"> special design service </a>
                  here.
                </div>
                <ul className="design-items" style={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  justifyContent: 'space-between', 
                  listStyleType: 'none', 
                  padding: 0,
                  marginTop: 50 
                }}>
                  {designItems.map((item) => (
                    <li key={item.id} className={`design-item f_design-item f_design-item-${item.id}`} style={{ width: 'calc(25% - 10px)', marginBottom: '20px', position: 'relative', backgroundColor:"#f7f7f7",  }}>
                      <a
                        href="javascript: void(0);"
                        data-spized-variant-switch="true"
                        data-spized-variant-switch-options={`{"url":"${item.variantUrl}"}`}
                        className="design-image"
                      >
                        <img
                          src={item.dataSrc}
                          alt={item.altText}
                          title={item.title}
                          style={{
                            width: '100%', // Full width
                            height: 'auto', // Maintain aspect ratio
                            display: 'block', // Prevent extra space below images
                            objectFit: 'cover', // Cover the space while maintaining aspect ratio
                          }}
                        />
                      </a>
  
                      <div className="f_images-container is--hidden" data-cover-index="0">
                        <div className="f_image-0">
                          <input className="f_img-thumbnail" type="hidden" value={item.dataSrc} />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Designs;

import React from "react";

function TakeClub() {
  return (
    <div>
      <div className="cms-section no-overflow pos-3 cms-section-default">
        <div className="cms-section-default full-width">
          <div className="cms-block block-container-2 title-text-box pos-0 cms-block-text">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row">
                <div
                  className="col-12"
                  data-cms-element-id="2eb0409c0b874fceacad996d8f8bf32a"
                >
                  <div className="cms-element-text">
                    <div className="text-box-emotion">
                      <h3 className="t1">Take your club to the next level</h3>
                      <p className="t2">
                        Do it like the pros and design a customised kit for your
                        team in just minutes with Spized.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cms-block block-container-2 pos-1 cms-block-spized-youtube-video">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row">
                <div
                  className="col-12"
                  data-cms-element-id="bcfd048142174e16b298b7e45a7526ff"
                >
                  <div className="cms-element-youtube-video">
                    <iframe
                      src="https://www.youtube-nocookie.com/embed/gFpLfsJ0r3k?rel=0&autoplay=1&mute=1&disablekb=1"
                      className="cms-element-youtube-video__video"
                      allow="autoplay"
                      title="Take Your Club to the Next Level"
                      style={{ width: "100%", height: "100%", aspectRatio: "16/9", border: "none" }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakeClub;

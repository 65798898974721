import api from "../api";
import { register, profile,Config,menu} from "../api_endpoints";
const { REGISTER_API, LOGIN_API,LOGOUT_API} = register;
const { UPDATEPROILE_API,PROILE_API,IMAGEUPDATE_API} = profile;
const { CONFIG_API} = Config;
const { MENU_API} = menu;

export const config_Api = async () => {
  let result = [];
  try {
    const response = await api.post(CONFIG_API);
    console.log("config : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const get_register = async (data) => {
  let result = [];
  try {
    const response = await api.post(REGISTER_API, data);
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};
export const get_login = async (data) => {
  let result = [];
  try {
    console.log("data : ", data);
    const response = await api.post(LOGIN_API, data);
    console.log("Login : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const get_logout = async (token) => {
  let result = [];
  try {
    const response = await api.post(LOGOUT_API,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Logout : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const updateProfile = async (data,token) => {
  let result = [];
  try {
    const response = await api.post(UPDATEPROILE_API, data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const getProfile = async (token) => {
  let result = [];
  try {
    const response = await api.post(PROILE_API,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const updateImage = async (data,token) => {
  let result = [];
  try {
    console.log("data : ",data)
    const response = await api.post(IMAGEUPDATE_API,data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};

export const menuSection = async () => {
  let result = [];
  try {
    const response = await api.post(MENU_API,{});
    console.log("register : ", response);
    result = response.data;
  } catch (error) {
    console.error("GET_ALL_PRODUCT_API_ERROR..........", error);
  }
  return result;
};


// export const getCart_data = async (user) => {
//   try {
//     console.log("user", user);
//     // const { user_id } = user;
//     // console.log("user",user_id);
//     // console.log("user_id to fetch cart : ", user_id);
//     const response = await api.get(`${cart_add_API}?user_id=${user}`);
//     console.log("response popular cart data : ", response);
//     return response;
//   } catch (error) {
//     console.log("GET_Allcart_API_ERROR..........");
//   }
// };

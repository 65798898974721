import React from "react";

function Inspire() {
  return (
    <div>
      <div
        className="tab-pane f_detail-tab-panel"
        id="inspiration-tab-panel"
        role="tabpanel"
        aria-labelledby="inspiration-tab"
      >
        <div className="offcanvas-header p-0">
          <div className="nav-item f_nav-item">
            <a
              className="nav-link product-detail-tab-navigation-link f_nav-link"
              href="javascript: void(0);"
              data-detail-tab="true"
              data-tab-selector="#inspiration-tab-panel"
            >
              Inspiration
            </a>
          </div>
        </div>
        <div className="offcanvas-body">
          <div className="best-design-container">
            <div className="best-design-container-inner">
              <div className="best-design-product-box">
                <div className="best-design-img-container">
                  <img
                    width="300"
                    height="300"
                    src="https://backend.tboxlabs.com/2022/2022-07-13/12679d2c-d2ca-a0c2-db36-e410f37019d4_781001.png"
                    className="f_lazy"
                    itemprop="image"
                  />
                </div>
                <div className="best-design-buy-widget-container">
                  <a
                    href="../../configurator04dd.html?apiLikeCfgId=4e4193130e2d4a8f25f76d584fcb2c32c77e11e79ad04c975ec74f843464544a7badbe6983c52f58ec21120fc9d4c61bf921476c28b515363d205d51ee417ef4&amp;apiArticle=781001&amp;productNumber=781001.SPIZED_COLORSCRATCH"
                    className="btn btn-primary btn-block btn-buy button2 f_customize-button"
                    data-default-configurator-url="/en/configurator?apiLikeCfgId=4e4193130e2d4a8f25f76d584fcb2c32c77e11e79ad04c975ec74f843464544a7badbe6983c52f58ec21120fc9d4c61bf921476c28b515363d205d51ee417ef4&apiArticle=781001&productNumber=781001.SPIZED_COLORSCRATCH"
                    title="Customize now"
                    aria-label="Customize now"
                    target="_blank"
                  >
                    Customize now
                  </a>
                  <input
                    type="hidden"
                    name="lineItems[c0386fdb388b4c2aadc9adb5af5be91e][id]"
                    value="c0386fdb388b4c2aadc9adb5af5be91e"
                  />
                  <input
                    type="hidden"
                    name="product-name"
                    value="Rio men’s football jersey"
                  />
                  <input type="hidden" name="brand-name" value />
                </div>
              </div>
              <div className="best-design-product-box">
                <div className="best-design-img-container">
                  <img
                    width="300"
                    height="300"
                    src="https://backend.tboxlabs.com/2021/2021-12-30/557aead7-df8b-3987-6ae2-71d01e7672ef_781001.png"
                    className="f_lazy"
                    itemprop="image"
                  />
                </div>
                <div className="best-design-buy-widget-container">
                  <a
                    href="../../configuratorfba3.html?apiLikeCfgId=1986547e5c00e0f81e6019a9b47623f48f1967181cce9d78b5a903ab51480df997912ae3c157540bc152bb54b28b89bc7d77e17e0df569463f7319a6efdf7e0b&amp;apiArticle=781001&amp;productNumber=781001.SPIZED_COLORSCRATCH"
                    className="btn btn-primary btn-block btn-buy button2 f_customize-button"
                    data-default-configurator-url="/en/configurator?apiLikeCfgId=1986547e5c00e0f81e6019a9b47623f48f1967181cce9d78b5a903ab51480df997912ae3c157540bc152bb54b28b89bc7d77e17e0df569463f7319a6efdf7e0b&apiArticle=781001&productNumber=781001.SPIZED_COLORSCRATCH"
                    title="Customize now"
                    aria-label="Customize now"
                    target="_blank"
                  >
                    Customize now
                  </a>
                  <input
                    type="hidden"
                    name="lineItems[c0386fdb388b4c2aadc9adb5af5be91e][id]"
                    value="c0386fdb388b4c2aadc9adb5af5be91e"
                  />
                  <input
                    type="hidden"
                    name="product-name"
                    value="Rio men’s football jersey"
                  />
                  <input type="hidden" name="brand-name" value />
                </div>
              </div>
              <div className="best-design-product-box">
                <div className="best-design-img-container">
                  <img
                    width="300"
                    height="300"
                    src="https://backend.tboxlabs.com/2022/2023-01-26/108503f9-8812-afe7-72f0-9301a205aa1e_781001.png"
                    className="f_lazy"
                    itemprop="image"
                  />
                </div>
                <div className="best-design-buy-widget-container">
                  <a
                    href="../../configuratorfc16.html?apiLikeCfgId=70279667e4b24f463516a12da158dcd7fb1a742264592061f218b741035bcd730dd42bb750fb99d01caf0919f0ab6bc627384739b433e26f32298c37faa00799&amp;apiArticle=781001&amp;productNumber=781001.SPIZED_COLORSCRATCH"
                    className="btn btn-primary btn-block btn-buy button2 f_customize-button"
                    data-default-configurator-url="/en/configurator?apiLikeCfgId=70279667e4b24f463516a12da158dcd7fb1a742264592061f218b741035bcd730dd42bb750fb99d01caf0919f0ab6bc627384739b433e26f32298c37faa00799&apiArticle=781001&productNumber=781001.SPIZED_COLORSCRATCH"
                    title="Customize now"
                    aria-label="Customize now"
                    target="_blank"
                  >
                    Customize now
                  </a>
                  <input
                    type="hidden"
                    name="lineItems[c0386fdb388b4c2aadc9adb5af5be91e][id]"
                    value="c0386fdb388b4c2aadc9adb5af5be91e"
                  />
                  <input
                    type="hidden"
                    name="product-name"
                    value="Rio men’s football jersey"
                  />
                  <input type="hidden" name="brand-name" value />
                </div>
              </div>
              <div className="best-design-product-box">
                <div className="best-design-img-container">
                  <img
                    width="300"
                    height="300"
                    src="https://backend.tboxlabs.com/2021/2021-01-23/28fd1923-8783-845f-9c4c-900a30018f58_781001.png"
                    className="f_lazy"
                    itemprop="image"
                  />
                </div>
                <div className="best-design-buy-widget-container">
                  <a
                    href="../../configurator53e6.html?apiLikeCfgId=40c9a2c5191a7bcb2f518d03228ed9eedd13e0d2d8345f9140177c62622d50fe5828af81a3ccf290a293a74917489f71921f93ac2219a3280ba00a47c2bc8a39&amp;apiArticle=781001&amp;productNumber=781001.SPIZED_COLORSCRATCH"
                    className="btn btn-primary btn-block btn-buy button2 f_customize-button"
                    data-default-configurator-url="/en/configurator?apiLikeCfgId=40c9a2c5191a7bcb2f518d03228ed9eedd13e0d2d8345f9140177c62622d50fe5828af81a3ccf290a293a74917489f71921f93ac2219a3280ba00a47c2bc8a39&apiArticle=781001&productNumber=781001.SPIZED_COLORSCRATCH"
                    title="Customize now"
                    aria-label="Customize now"
                    target="_blank"
                  >
                    Customize now
                  </a>
                  <input
                    type="hidden"
                    name="lineItems[c0386fdb388b4c2aadc9adb5af5be91e][id]"
                    value="c0386fdb388b4c2aadc9adb5af5be91e"
                  />
                  <input
                    type="hidden"
                    name="product-name"
                    value="Rio men’s football jersey"
                  />
                  <input type="hidden" name="brand-name" value />
                </div>
              </div>
              <div className="best-design-product-box">
                <div className="best-design-img-container">
                  <img
                    width="300"
                    height="300"
                    src="https://backend.tboxlabs.com/2021/2023-01-26/6972383c-cbf5-c015-0e54-081f2af3ce3a_781001.png"
                    className="f_lazy"
                    itemprop="image"
                  />
                </div>
                <div className="best-design-buy-widget-container">
                  <a
                    href="../../configurator87cd.html?apiLikeCfgId=5b490c9def313d53836323a488c3755cebcb9a9065802e6c450d6539ae292a4309885673bb0cfd76ae9483ce14ef4ad3128adaa048173366c94988a8b67c213e&amp;apiArticle=781001&amp;productNumber=781001.SPIZED_COLORSCRATCH"
                    className="btn btn-primary btn-block btn-buy button2 f_customize-button"
                    data-default-configurator-url="/en/configurator?apiLikeCfgId=5b490c9def313d53836323a488c3755cebcb9a9065802e6c450d6539ae292a4309885673bb0cfd76ae9483ce14ef4ad3128adaa048173366c94988a8b67c213e&apiArticle=781001&productNumber=781001.SPIZED_COLORSCRATCH"
                    title="Customize now"
                    aria-label="Customize now"
                    target="_blank"
                  >
                    Customize now
                  </a>
                  <input
                    type="hidden"
                    name="lineItems[c0386fdb388b4c2aadc9adb5af5be91e][id]"
                    value="c0386fdb388b4c2aadc9adb5af5be91e"
                  />
                  <input
                    type="hidden"
                    name="product-name"
                    value="Rio men’s football jersey"
                  />
                  <input type="hidden" name="brand-name" value />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inspire;

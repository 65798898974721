import React from "react";
import { IoMdCheckmark } from "react-icons/io";

function BannerBottom() {
  return (
    <div>
      <div className="cms-section  pos-1 cms-section-default">
        <div className="cms-section-default full-width">
          <div className="cms-block  pos-0 cms-block-text">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row ">
                <div
                  className="col-12"
                  data-cms-element-id="018ba954a62c7ab4baad156c58535517"
                >
                  <div className="cms-element-text">
                    <div className="list-text-sign-box">
                      <ul className="list-text-sign-box-wrapper t2spec block-container-2">
                        <li>
                          <IoMdCheckmark
                            style={{ fontSize: "32px", marginRight: "10px" }}
                          />
                          All printing costs included
                        </li>
                        <li>
                          <IoMdCheckmark
                            style={{ fontSize: "32px", marginRight: "10px" }}
                          />
                          Fast production &amp; delivery times
                        </li>
                        <li>
                          <IoMdCheckmark
                            style={{ fontSize: "32px", marginRight: "10px" }}
                          />
                          Professional quality - 100% Made in EU
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerBottom;

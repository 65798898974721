import React, { useState } from "react";
import Description from "../DetailsTabs/Description";
import Designs from "../DetailsTabs/Designs";
import Colors from "../DetailsTabs/Colors";
import Pickup from "../DetailsTabs/Pickup";
import SizeTable from "../DetailsTabs/SizeTable";
import Shipping from "../DetailsTabs/Shipping";
import Inspire from "../DetailsTabs/Inspire";

function DetailsTab({ product }) {
  const [activeTab, setActiveTab] = useState("description");

  const renderTabContent = () => {
    switch (activeTab) {
      case "description":
        return <Description />;
      case "designs":
        return <Designs />;
      case "colors":
        return <Colors />;
      case "pickup":
        return <Pickup />;
      case "sizes":
        return <SizeTable />;
      case "shipping":
        return <Shipping />;
      case "inspiration":
        return <Inspire />;
      default:
        return <Description />;
    }
  };

  return (
    <div className="product-detail-tabs">
      <div className="card card-tabs" style={{ borderWidth: 0 }}>
        <div className="card-header product-detail-tab-navigation" style={{ borderWidth: 0 }}>
          <ul className="nav nav-tabs product-detail-tab-navigation-list" role="tablist" style={{ borderWidth: 0 }}>
            {[
              { id: "description", label: "Description" },
              { id: "designs", label: "Designs" },
              { id: "colors", label: "Colours" },
              { id: "pickup", label: "Prices & delivery times" },
              { id: "sizes", label: "Size table" },
              { id: "shipping", label: "Shipping costs" },
              { id: "inspiration", label: "Inspiration" },
            ].map((tab) => (
              <li className="nav-item" key={tab.id}>
                <a
                  className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                  onClick={() => setActiveTab(tab.id)}
                  style={{
                    textDecoration: "none",
                    borderBottom: activeTab === tab.id ? "2px solid #000" : "none",
                    paddingBottom: "8px",
                    fontWeight:"500",
                    paddingRight:"20px",
                    paddingLeft:"20px"
                  }}
                  role="button"
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="product-detail-tabs-content card-body">
          <div className="tab-content">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsTab;

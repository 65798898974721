import React, { useState } from "react";

function Pickup() {
  const [quantity, setQuantity] = useState(10);
  const maxQuantity = 100;
  const minQuantity = 1;

  const scalePrices = [
    { to: 1, price: 56.9 },
    { to: 2, price: 54.9 },
    { to: 4, price: 51.9 },
    { to: 9, price: 46.9 },
    { to: 19, price: 36.9 },
    { to: 34, price: 34.9 },
    { to: 49, price: 32.9 },
    { to: 99, price: 30.9 },
    { to: 100, price: 29.9 },
  ];

  const getPrice = (quantity) => {
    let price = scalePrices[scalePrices.length - 1].price;
    for (let i = 0; i < scalePrices.length; i++) {
      if (quantity <= scalePrices[i].to) {
        price = scalePrices[i].price;
        break;
      }
    }
    return price;
  };

  const handleSliderChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
  };

  const handleDecrease = () => {
    if (quantity > minQuantity) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    if (quantity < maxQuantity) {
      setQuantity(quantity + 1);
    }
  };

  const price = getPrice(quantity);
  const discountPercentage = 35; // Assuming a fixed discount; you may adjust it as needed.

  return (
    <div>
      <div
        className="tab-pane f_detail-tab-panel"
        id="prices-tab-panel"
        role="tabpanel"
        aria-labelledby="prices-tab"
      >
        <div className="offcanvas-body">
          <div className="product-detail-prices tab-pane-container">
            <div
              className="h3 product-detail-description-title"
              style={{
                fontFamily: "sans-serif",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Prices & delivery times
            </div>
            <div className="product-detail-prices-text">
              For an exact price calculation, access our graduated prices here.
              For large purchase quantities, the fixed costs are distributed
              over a larger number of items. We pass these savings on to you
              1:1. In contrast to many providers, we don’t work with minimum
              purchase quantities. This is how we also enable you to
              order/reorder in small quantities. We want to satisfy you
              completely. That’s why you always pay the original price even for
              repeat orders. <br />
              <br />
              Example: You ordered 15 jerseys for your team in the past. Due to
              a new commitment, you now need another jersey. You don’t pay the
              1-piece price, but the 10-piece price as with your previous order.
            </div>

            <div style={styles.container}>
              <div style={styles.rangeBlock}>
                <div style={styles.sliderBox}>
                  <div style={styles.sliderContainer}>
                    <input
                      className="f_range-slider"
                      type="range"
                      min={minQuantity}
                      max={maxQuantity}
                      value={quantity}
                      onChange={handleSliderChange}
                      style={{
                        ...styles.slider,
                        backgroundSize: `${
                          (quantity / maxQuantity) * 100
                        }% 100%`,
                      }}
                    />
                    <div
                      style={{
                        ...styles.rangeInfo,
                        left: `${(quantity / maxQuantity) * 100}%`,
                      }}
                    >
                      <label style={styles.rangeLabel}>{quantity}</label>
                      <span style={styles.pieceText}>pcs</span>
                    </div>
                  </div>
                  <ul style={styles.ruler}>
                    {[1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                      (num, index) => (
                        <li key={index} style={styles.rulerItem}>
                          <span style={styles.rulerNumber}>{num}</span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div style={styles.calibrationBox}>
                  <button style={styles.button} onClick={handleDecrease}>
                    -
                  </button>
                  <input
                    type="text"
                    style={styles.input}
                    value={quantity}
                    readOnly
                  />
                  <button style={styles.button} onClick={handleIncrease}>
                    +
                  </button>
                </div>
              </div>
              <div style={styles.priceBlock}>
                <div style={styles.priceContent}>
                  <span
                    style={{
                      ...styles.priceLine,
                      fontFamily: "ttsupermolotneue",
                    }}
                  >
                    Unit price for <strong>{quantity}</strong> pcs:
                    <span style={styles.articlePrice}>€{price.toFixed(2)}</span>
                  </span>
                  <span
                    style={{
                      ...styles.discountText,
                      fontFamily: "ttsupermolotneue",
                    }}
                  >
                    {discountPercentage}% Quantity discount
                  </span>
                </div>
                <div style={styles.taxContainer}>
                  <a
                    href="../../widgets/cms/80625a029c9a493daaad688e14c91ec0.html"
                    title="Prices incl. VAT plus shipping costs"
                    style={styles.taxLink}
                  >
                    Prices incl. VAT plus shipping costs
                  </a>
                </div>
              </div>
            </div>

            <div className="block-prices-content">
              <div className="product-block-prices">
                <table className="table product-block-prices-grid">
                  <thead className="product-block-prices-head">
                    <tr className="product-block-prices-row">
                      <th
                        scope="col"
                        className="product-block-prices-cell t2spec"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="product-block-prices-cell t2spec"
                      >
                        Unit price
                      </th>
                      <th
                        scope="col"
                        className="product-block-prices-cell t2spec"
                      >
                        Delivery date
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="product-block-prices-body"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {[
                      {
                        quantity: 1,
                        price: 56.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 2,
                        price: 54.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 4,
                        price: 51.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 9,
                        price: 46.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 19,
                        price: 36.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 34,
                        price: 34.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 49,
                        price: 32.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 99,
                        price: 30.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                      {
                        quantity: 100,
                        price: 29.9,
                        dateRange: "29.10 - 30.10.2024",
                      },
                    ].map((offer, index) => (
                      <tr
                        key={index}
                        className="product-block-prices-row"
                        itemProp="offers"
                        itemScope
                        itemType="https://schema.org/Offer"
                      >
                        <th
                          scope="row"
                          className="product-block-prices-cell product-block-prices-cell-thin default"
                        >
                          <div
                            itemProp="priceCurrency"
                            content="b7d2554b0ce847cd82f3ac9bd1c0dfca"
                          />
                          <div itemProp="price" content={offer.price} />
                          <link
                            itemProp="availability"
                            href="https://schema.org/InStock"
                          />
                          To
                          <span className="product-block-prices-quantity default">
                            {offer.quantity}
                          </span>
                        </th>
                        <td className="product-block-prices-cell">
                          <div>€{offer.price.toFixed(2)}*</div>
                        </td>
                        <td className="product-block-prices-cell product-block-prices-cell-thin default">
                          {offer.dateRange}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = {
  container: {
    maxWidth: "100%",
    margin: "20px auto",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  rangeBlock: {
    marginBottom: "20px",
    width: "800px",
  },
  sliderBox: {
    marginBottom: "20px",
  },
  sliderContainer: {
    position: "relative",
  },
  slider: {
    width: "100%",
    height: "8px",
    borderRadius: "4px",
    background: "#ddd",
    appearance: "none",
    outline: "none",
    transition: "background-size 0.3s ease",
  },
  rangeInfo: {
    position: "absolute",
    top: "-30px",
    transform: "translateX(-50%)",
    textAlign: "center",
  },
  rangeLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
  },
  pieceText: {
    fontSize: "12px",
    color: "#777",
    display: "block",
  },
  ruler: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    padding: "0",
    listStyle: "none",
  },
  rulerItem: {
    fontSize: "12px",
    color: "#999",
  },
  rulerNumber: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
  },
  calibrationBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    width: "30px",
    height: "30px",
    fontSize: "18px",
    cursor: "pointer",
  },
  input: {
    width: "80px",
    height: "30px",
    textAlign: "center",
    border: "1px solid #ddd",
    borderRadius: "4px",
    margin: "0 10px",
    fontSize: "16px",
  },
  priceBlock: {},
  priceContent: {
    fontSize: "16px",
    color: "#333",
    textAlign: "center",
  },
  priceLine: {
    display: "block",
    margin: "10px 0",
    color: "#333",
  },
  articlePrice: {
    fontWeight: "bold",
    color: "#007bff",
    marginLeft: "5px",
  },
  discountText: {
    fontSize: "14px",
    color: "#28a745",
    display: "block",
    marginTop: "5px",
  },
  taxContainer: {
    textAlign: "center",
    marginTop: "10px",
  },
  taxLink: {
    fontSize: "12px",
    color: "#888",
    textDecoration: "none",
  },
};

export default Pickup;

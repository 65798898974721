const baseURL= "https://sheildsports.com/main/api/v1/"

//config endpoint
export const Config={
    CONFIG_API:baseURL+"webconfig",
}

//register endpoint
export const register={
    REGISTER_API:baseURL+"customer/register",
    LOGIN_API:baseURL+"customer/login",
    LOGOUT_API:baseURL+"customer/logout",
}

//profile endpoint
export const profile={
    UPDATEPROILE_API:baseURL+"customer/updateprofile",
    PROILE_API:baseURL+"customer/profile",
    IMAGEUPDATE_API:baseURL+"customer/updateprofileimg",
}

//profile endpoint
export const menu={
    MENU_API:baseURL+"getAllMenus",
}
